const { showElement, hideElement } = require("/src/common/js");

const feedbackFormModal = document.forms.feedbackFormModal;
const feedbackFormFooter = document.forms.feedbackFormFooter;

feedbackFormModal?.addEventListener('submit', onFormSubmit);
feedbackFormFooter?.addEventListener('submit', onFormSubmit);

function onFormSubmit(e) {
  e.preventDefault();

  sendMail(e.target);
  e.target.reset();
}

async function sendMail(formRef) {
  console.log('formRef', formRef);
  const formData = new FormData(formRef);
  let sendURL = '/sendmail.php';
  const msgSuccess = formRef?.querySelector('[data-response=success]');
  const msgError = formRef?.querySelector('[data-response=error]');

  // showLoader();
  try {
    let response = await fetch(sendURL, {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      showElement(msgSuccess);
    }
    else showElement(msgError);
  } catch (exceptionVar) {
    showElement(msgError);
  } finally {
    // hideLoader();
    const hideMsg = setTimeout(() => {
      // hideElement(formRef);
      hideElement(msgError);
      hideElement(msgSuccess);
      clearTimeout(hideMsg);
    }, 5000);
  }
}
