document.addEventListener("click", onVideoBtnClick)

function onVideoBtnClick(e) {
  if (!e.target.hasAttribute('data-video-btn')) return
  const videoId = e.target?.dataset.id;
  const className = e.target?.dataset.class;
  const boxRef = e.target.closest('[data-video-box]');
  if (!videoId || !boxRef) return
  renderYoutubeIframe(videoId, boxRef, className);
}

export default function renderYoutubeIframe(videoId, boxRef, className) {
  var iframe = document.createElement('iframe');
  iframe.setAttribute('src', 'https://www.youtube.com/embed/' + videoId + '?autoplay=1');
  // iframe.setAttribute('src', 'https://www.youtube.com/embed/' + videoId + '?autoplay=1&amp;controls=0');
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allow', 'autoplay; encrypted-media');
  iframe.setAttribute('allowfullscreen', '1');
  iframe.classList.add(className);
  boxRef.innerHTML = '';
  boxRef.appendChild(iframe);
}
