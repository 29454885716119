import { hideElement, showElement } from "/src/common/js";

const feedbackModal = document.querySelector('[data-modal="feedback"]');
const feedbackModalBackdrop = feedbackModal?.querySelector('[data-modal-backdrop]');
const feedbackModalBtnClose = feedbackModal?.querySelector('[data-modal-btn-close]');

document.addEventListener('click', checkBtnAndOpenModal);

function checkBtnAndOpenModal(e) {
  const isFeedbackModalBtnOpen = e.target.hasAttribute('data-modal-btn-open') ? e.target.dataset?.modalBtnOpen === "feedback" : false;
  if (isFeedbackModalBtnOpen) openModal();
}
export function openModal() {
  showElement(feedbackModal);
  document.body.classList.add('modalIsOpen');

  feedbackModalBackdrop?.addEventListener('click', onBackdropClick);
  feedbackModalBtnClose?.addEventListener('click', closeModal);
  window.addEventListener('keydown', onEscKeyPress);
}
export function closeModal() {
  hideElement(feedbackModal);
  document.body.classList.remove("modalIsOpen");

  feedbackModalBackdrop?.removeEventListener('click', onBackdropClick);
  feedbackModalBtnClose?.removeEventListener('click', closeModal);
  window.removeEventListener('keydown', onEscKeyPress);
}

function onBackdropClick(e) {
  if (e.currentTarget === e.target) closeModal();
}
function onEscKeyPress(e) {
  if (feedbackModalBackdrop?.classList.contains('isHidden')) return;
  if (e.code === 'Escape') closeModal();
}


// const modalForm = document.forms.modalForm;
// const contactForm = document.forms.contactForm;

// modalForm.addEventListener('submit', onFormSubmit);
// contactForm.addEventListener('submit', onFormSubmit);

// function onFormSubmit(e) {
//   e.preventDefault();
  
//   sendMail(e.target);
//   e.target.reset();
// }

// async function sendMail(formRef) {
//   const formData = new FormData(formRef);
//   let sendURL = '/sendmail.php';
//   const msgSuccess = document.querySelector('.modal__msg--success');
//   const msgError = document.querySelector('.modal__msg--error ');
  
//   showLoader();
//   try {
//     let response = await fetch(sendURL, {
//       method: "POST",
//       body: formData,
//     });
//     if (response.ok) {
//       showElement(msgSuccess);
//     }
//     else showElement(msgError);
//   } catch (exceptionVar) {
//     showElement(msgError);
//   } finally {
//     hideLoader();
//     const hideMsg = setTimeout(() => {
//       hideElement(refs.modalForm);
//       hideElement(msgError);
//       hideElement(msgSuccess);
//       clearTimeout(hideMsg);
//     }, 5000);
//   }
// }